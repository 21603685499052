<template>
	<v-sheet class="vendor" style="height: calc(100vh - 88px)">
		<template v-if="contentLoaded">
			<v-layout class="align-center light-blue-bg px-4 py-2">
				<v-flex md12>
					<div class="d-flex">
						<p class="my-auto font-level-3-bold">
							<ShowValue :object="vendor" object-key="display_name" label="vendor name"></ShowValue>
							<v-chip color="cyan" class="ml-2 mr-2" label text-color="white"
								>{{ vendor.barcode }}
							</v-chip>
						</p>
						<v-spacer></v-spacer>

						<v-btn
							v-if="getPermission('supplier:create')"
							v-on:click="doAction('clone')"
							color="blue darken-4 text-white"
							class="ml-2"
							depressed
							tile
						>
							<v-icon small left>mdi-content-duplicate</v-icon>Clone
						</v-btn>

						<v-btn
							v-if="getPermission('supplier:update')"
							v-on:click="doAction('edit')"
							color="blue darken-4 text-white"
							class="ml-2"
							depressed
							tile
						>
							<v-icon small left>mdi-pencil</v-icon>Edit
						</v-btn>
						<template v-if="getPermission('supplier:delete')">
							<v-btn
								v-if="!vendor.is_owner"
								v-on:click="doAction('delete')"
								color="blue darken-4 text-white"
								class="ml-2"
								depressed
								tile
							>
								<v-icon small left>mdi-delete</v-icon>Delete
							</v-btn>
						</template>
						<template v-if="!vendor.is_owner">
							<template v-if="getPermission('supplier:update')">
								<v-btn
									v-if="vendor.activated"
									v-on:click="doAction('deactivate')"
									color="blue darken-4 text-white"
									class="ml-2"
									depressed
									tile
								>
									<v-icon small left>mdi-cancel</v-icon>De-activate
								</v-btn>
								<v-btn
									v-else
									v-on:click="doAction('activate')"
									color="blue darken-4 text-white"
									class="ml-2"
									depressed
									tile
								>
									<v-icon small left>mdi-check-all</v-icon>Activate
								</v-btn>
							</template>
						</template>
						<template>
							<v-btn
								v-if="false && vendorTab == 'address-book'"
								v-on:click="doAction('new-address')"
								color="blue darken-4 text-white"
								class="ml-2"
								depressed
								tile
							>
								<v-icon small left>mdi-playlist-plus</v-icon>Add New Address
							</v-btn>
						</template>
					</div>
					<div class="d-flex mt-4">
						<div class="w-10 pr-3">
							<ImageTemplate circle :src="vendor.image"></ImageTemplate>
						</div>
						<div class="w-100 pr-3">
							<div class="d-flex mt-4">
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600">Supplier ID : </span>
										<span class="fw-500 no-wrap">
											<ShowValue :object="vendor" object-key="barcode" label="supplier"> </ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600 mr-2">Company Name : </span>
										<span class="fw-500">
											<span class="fw-500">
												<ShowValue :object="vendor" object-key="company_name" label="company name"></ShowValue>
											</span>
										</span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600">Display Name : </span>
										<span class="fw-500 no-wrap">
											<ShowValue :object="vendor" object-key="display_name" label="display name"> </ShowValue>
										</span>
									</div>
								</div>
							</div>
							<div class="d-flex">
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600">Phone Number : </span>
										<span class="fw-500 no-wrap">
											<ShowValue :object="vendor" object-key="phone" label="phone"></ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600 mr-2">Email : </span>
										<span class="fw-500">
											<ShowValue :object="vendor" object-key="email" label="email"></ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600">UEN : </span>
										<span class="fw-500">
											<ShowValue :object="vendor" object-key="uen" label="uen"></ShowValue>
										</span>
									</div>
								</div>
							</div>
							<div class="d-flex">
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600">Address : </span>
										<span class="fw-500 no-wrap">
											<ShowValue
												:object="vendor.billing_address"
												object-key="address_line_1"
												label="address"
											></ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600 mr-2">Country : </span>
										<span class="fw-500">
											<ShowValue
												:object="vendor.billing_address"
												object-key="address_country"
												label="country"
											></ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600">Postal Code : </span>
										<span class="fw-500">
											<ShowValue
												:object="vendor.billing_address"
												object-key="address_postal_code"
												label="postalcode"
											></ShowValue>
										</span>
									</div>
								</div>
							</div>
							<div class="d-flex">
								<div class="mt-2">
									<span class="fw-600">Location : </span>
									<span class="fw-500">
										<ShowValue
											:object="vendor.billing_address"
											object-key="nest_location"
											label="Location"
										></ShowValue>
									</span>
								</div>
							</div>
						</div>
					</div>
				</v-flex>
			</v-layout>
			<div>
				<v-tabs
					v-model="vendorTab"
					background-color="transparent"
					color="blue"
					class="custom-tab-transparent"
					active-class="blue darken-4 text-white"
					hide-slider
				>
					<v-tab href="#overview"> Overview </v-tab>
					<v-tab href="#comment" class="pr-8">
						<v-badge color="cyan" :content="vemdorcomment"> Comments </v-badge>
					</v-tab>
					<v-tab href="#address-book" v-if="false">
						Address Book
						<v-icon small left>mdi-format-list-text</v-icon>
					</v-tab>
					<v-tab href="#asset" class="pr-8">
						<v-badge color="cyan" :content="vendorAssetCount"> Asset </v-badge>
					</v-tab>
					<v-tab href="#asset-stock" class="pr-8">
						<v-badge color="cyan" :content="vendorAssetStockCount"> Asset Stock </v-badge>
					</v-tab>
					<v-tab href="#inventory" class="pr-8">
						<v-badge color="cyan" :content="vendorinventories"> Perishables </v-badge>
					</v-tab>
					<v-tab href="#services" class="pr-8">
						<v-badge color="cyan" :content="vendorServices"> Services </v-badge>
					</v-tab>
					<v-tab href="#file">
						<v-badge color="cyan" :content="vemdorFile"> Files </v-badge>
					</v-tab>
					<v-tab href="#mail" v-if="false"> Mails </v-tab>
					<v-tab href="#event">
						<v-badge color="cyan" :content="vemdorLog"> Logs </v-badge>
					</v-tab>
					<v-tabs-items v-model="vendorTab">
						<v-tab-item value="overview">
							<v-container>
								<v-row>
									<v-col md="8" class="bg-light-grey">
										<v-expansion-panels flat accordion tile class="mt-5 transparent-panels">
											<!-- <v-expansion-panel>
												<v-expansion-panel-header>Address</v-expansion-panel-header>
												<v-expansion-panel-content>
													<v-layout v-if="vendor && vendor.billing_address">
														<v-flex>
															<p class="mb-1 bold-500">Billing Address</p>
															<p class="m-0">
																<span class="bold-600">{{ vendor.billing_address.name
																}}</span>
																{{ addressFormat(vendor.billing_address) }}
															</p>
														</v-flex>
														<v-flex v-if="false" class="my-auto text-right">
															<v-btn icon small color="blue darken-4"
																v-on:click="updateAddressDialog('billing')">
																<v-icon small>mdi-pencil</v-icon>
															</v-btn>
														</v-flex>
													</v-layout>
													<v-layout class="mt-6" v-if="vendor && vendor.shipping_address">
														<v-flex>
															<p class="mb-1 bold-500">Shipping Address</p>
															<p class="m-0">
																<span class="bold-600">{{ vendor.shipping_address.name
																}}</span>
																{{ addressFormat(vendor.shipping_address) }}
															</p>
														</v-flex>
														<v-flex class="my-auto text-right">
															<v-btn v-if="false" icon small color="blue darken-4"
																v-on:click="updateAddressDialog('shipping')">
																<v-icon small>mdi-pencil</v-icon>
															</v-btn>
														</v-flex>
													</v-layout>
												</v-expansion-panel-content>
											</v-expansion-panel> -->
											<v-expansion-panel v-if="false">
												<v-expansion-panel-header>Other Details</v-expansion-panel-header>
												<v-expansion-panel-content>
													<v-layout class="mb-1">
														<v-flex md5 class="text-muted">Supplier ID</v-flex>
														<v-flex md7>{{ vendor.barcode }}</v-flex>
													</v-layout>
													<v-hover v-slot="{ hover }">
														<v-layout class="mb-1">
															<v-flex md5 class="text-muted">Company Name</v-flex>
															<v-flex md7 class="text-truncate">
																{{ vendor.company_name }}
																<TextInputInternal
																	v-if="false"
																	field="company_name"
																	:uuid="uuid"
																	endpoint="vendor"
																	v-model="vendor.company_name"
																	v-on:success="getVendor()"
																></TextInputInternal>
															</v-flex>
														</v-layout>
													</v-hover>
													<v-hover v-slot="{ hover }">
														<v-layout class="mb-1">
															<v-flex md5 class="text-muted">Display Name</v-flex>
															<v-flex md7 class="text-truncate">
																{{ vendor.display_name }}
																<TextInputInternal
																	v-if="false"
																	field="display_name"
																	:uuid="uuid"
																	endpoint="vendor"
																	v-model="vendor.display_name"
																	v-on:success="getVendor()"
																></TextInputInternal>
															</v-flex>
														</v-layout>
													</v-hover>
													<v-hover v-slot="{ hover }">
														<v-layout class="mb-1">
															<v-flex md5 class="text-muted">Phone Number</v-flex>
															<v-flex md7 class="text-truncate"
																>{{ vendor.phone }}
																<PhoneInputInternal
																	v-if="false"
																	field="fax"
																	:uuid="uuid"
																	endpoint="vendor"
																	v-model="vendor.phone"
																	v-on:success="getVendor()"
																>
																</PhoneInputInternal>
															</v-flex>
														</v-layout>
													</v-hover>
													<v-hover v-slot="{ hover }">
														<v-layout>
															<v-flex md5 class="text-muted">Email</v-flex>
															<v-flex md7 class="text-truncate"
																>{{ vendor.email }}
																<TextInputInternal
																	v-if="false"
																	field="website"
																	:uuid="uuid"
																	endpoint="vendor"
																	v-model="vendor.email"
																	v-on:success="getVendor()"
																>
																</TextInputInternal>
															</v-flex>
														</v-layout>
													</v-hover>
													<v-hover v-slot="{ hover }">
														<v-layout>
															<v-flex md5 class="text-muted">UEN</v-flex>
															<v-flex md7 class="text-truncate"
																>{{ vendor.uen }}
																<TextInputInternal
																	v-if="false"
																	field="uen"
																	:uuid="uuid"
																	endpoint="vendor"
																	v-model="vendor.uen"
																	v-on:success="getVendor()"
																></TextInputInternal>
															</v-flex>
														</v-layout>
													</v-hover>
													<v-hover v-slot="{ hover }">
														<v-layout>
															<v-flex md5 class="text-muted">Email</v-flex>
															<v-flex md7 class="text-truncate"
																>{{ vendor.email }}
																<TextInputInternal
																	v-if="false"
																	field="website"
																	:uuid="uuid"
																	endpoint="vendor"
																	v-model="vendor.email"
																	v-on:success="getVendor()"
																>
																</TextInputInternal>
															</v-flex>
														</v-layout>
													</v-hover>
												</v-expansion-panel-content>
											</v-expansion-panel>
											<v-expansion-panel>
												<v-expansion-panel-header>
													<v-layout>
														<v-flex md10>Contact Persons ({{ contact_person.length }}) </v-flex>
														<v-flex md2 class="text-right">
															<v-icon
																v-on:click.stop.prevent="addPerson()"
																dense
																color="blue darken-4"
																class="mx-2"
															>
																mdi-plus-circle-outline</v-icon
															>
														</v-flex>
													</v-layout>
												</v-expansion-panel-header>
												<v-expansion-panel-content class="contact-person-list">
													<template v-if="contact_person.length">
														<v-layout v-for="(person, index) in contact_person" :key="index">
															<v-flex md12>
																<div>
																	<p class="text-truncate mb-1 bold-600 text-capitalize">
																		{{ person.full_name }}
																		<v-chip class="ma-2" label x-small color="green" v-if="person.primary == 1">
																			<span class="white--text">Primary</span>
																		</v-chip>
																	</p>
																	<v-menu left tile offset-x>
																		<template v-slot:activator="{ on, attrs }">
																			<v-btn
																				icon
																				small
																				color="blue darken-4"
																				class="btx-icon-top-right"
																				v-bind="attrs"
																				v-on="on"
																			>
																				<v-icon small>mdi-cog</v-icon>
																			</v-btn>
																		</template>

																		<v-list small>
																			<v-list-item link v-on:click="addPerson(person)">
																				<v-list-item-title>Edit </v-list-item-title>
																			</v-list-item>
																			<v-list-item link v-if="!person.primary" v-on:click="markAsPrimary(person.uuid)">
																				<v-list-item-title>Mark as Primary </v-list-item-title>
																			</v-list-item>
																			<v-list-item link v-if="false">
																				<v-list-item-title>Send Email </v-list-item-title>
																			</v-list-item>
																			<v-list-item link v-if="!person.primary" v-on:click="deletePerson(person.uuid)">
																				<v-list-item-title>Delete </v-list-item-title>
																			</v-list-item>
																		</v-list>
																	</v-menu>
																</div>
																<p class="mb-1 text-lowercase" v-if="person.email">
																	<v-icon left small>mdi-email</v-icon>{{ person.email }}
																</p>
																<p class="mb-1 text-lowercase" v-if="person.phone">
																	<v-icon left small>mdi-phone</v-icon>{{ person.phone }}
																</p>
																<p class="mb-1" v-if="person.display_name">
																	<v-icon left small>mdi-account</v-icon> {{ person.display_name }}
																</p>
																<p class="mb-1" v-if="person.designation">
																	<v-icon left small>mdi-briefcase</v-icon> {{ person.designation }}
																</p>
															</v-flex>
														</v-layout>
													</template>
												</v-expansion-panel-content>
											</v-expansion-panel>
										</v-expansion-panels>
									</v-col>
									<v-col md="4"> </v-col>
								</v-row>
							</v-container>
						</v-tab-item>
						<v-tab-item value="comment">
							<Comments
								v-if="vendorTab == 'comment' && uuid"
								class="mx-4"
								type-text="Vendor"
								type="vendor"
								:type-uuid="uuid"
							></Comments>
						</v-tab-item>
						<v-tab-item value="address-book">
							<SupplierAddressBook
								v-if="vendorTab == 'address-book'"
								type-text="Supplier"
								type="contact"
								:new-address="addressDialog"
								:type-uuid="uuid"
								v-on:close="addressDialog = false"
							>
							</SupplierAddressBook>
						</v-tab-item>
						<v-tab-item value="asset">
							<AssetListing
								v-if="vendor && vendor.id && vendorTab == 'asset'"
								class="mx-4"
								relation-type="supplier"
								:relation-type-id="vendor.id"
							></AssetListing>
						</v-tab-item>
						<v-tab-item value="asset-stock">
							<AssetStockListing
								v-if="vendor && vendor.id && vendorTab == 'asset-stock'"
								class="mx-4"
								relation-type="supplier"
								:relation-type-id="vendor.id"
							></AssetStockListing>
						</v-tab-item>
						<v-tab-item value="inventory">
							<InventoryListing
								v-if="vendor && vendor.id && vendorTab == 'inventory'"
								class="mx-4"
								relation-type="supplier"
								:relation-type-id="vendor.id"
							></InventoryListing>
						</v-tab-item>
						<v-tab-item value="services">
							<template v-if="uuid">
								<ServiceListing
									v-if="vendorTab == 'services'"
									class="mx-4"
									:uuid="uuid"
									type="supplier"
								></ServiceListing>
							</template>
						</v-tab-item>
						<v-tab-item value="file">
							<Files
								v-if="vendorTab == 'file'"
								class="mx-4"
								type-text="Supplier Files"
								type="contact"
								:type-uuid="uuid"
							></Files>
						</v-tab-item>
						<v-tab-item v-if="false" value="mail"> // mails </v-tab-item>
						<v-tab-item value="event">
							<EventListing
								v-if="vendorTab == 'event'"
								class="mx-4"
								type-text="Supplier"
								type="contact"
								:type-uuid="uuid"
							></EventListing>
						</v-tab-item>
					</v-tabs-items>
				</v-tabs>
				<!--  -->
				<CreateOrUpdatePerson
					:person-dialog="personDialog"
					:vendor-uuid="uuid"
					:person="personModel"
					:contactUuid="vendor.uuid"
					v-on:close="personDialog = false"
					v-on:success="getVendor"
				>
				</CreateOrUpdatePerson>
				<template v-if="mail_dialog && vendor && vendor.id">
					<SendMail
						:mail-dialog="mail_dialog"
						title="Send as Email"
						type="contact"
						v-on:close="mail_dialog = false"
						:type-id="vendor.id"
						v-on:success="getVendor()"
					></SendMail>
				</template>
				<!--  -->
			</div>
			<DeleteTemplate
				type="Supplier"
				:delete-text="deleteText"
				v-on:success="goBack()"
				v-on:close="deleteDialog = false"
				:delete-dialog="deleteDialog"
				:delete-url="deleteURL"
				:delete-note="
					vendor.activated
						? 'To preserve associated records, you could de-activate the Supplier instead.'
						: null
				"
			>
				<template v-if="vendor.activated" v-slot:extra-btn>
					<v-btn
						class="white--text"
						depressed
						color="blue darken-4"
						tile
						v-on:click="
							deleteDialog = false;
							deactivateDialog = true;
						"
					>
						De-activate
					</v-btn>
				</template>
			</DeleteTemplate>
			<DeactivateTemplate
				type="Supplier"
				:deactivate-text="deactivateText"
				v-on:success="goBack()"
				v-on:close="deactivateDialog = false"
				:deactivate-dialog="deactivateDialog"
				:deactivate-url="deactivateURL"
			>
			</DeactivateTemplate>
			<ActivateTemplate
				type="Supplier"
				:activate-text="activateText"
				v-on:success="goBack()"
				v-on:close="activateDialog = false"
				:activate-dialog="activateDialog"
				:activate-url="activateURL"
			>
			</ActivateTemplate>
		</template>
		<template v-else>
			<div class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</template>
	</v-sheet>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
	GetContact,
	MarkAsDefaultContactPerson,
	DeleteContactPerson,
} from "@/core/lib/contact.lib";
import ImageTemplate from "@/view/components/Image";
import CreateOrUpdatePerson from "@/view/components/CreateOrUpdatePerson";
import TextInputInternal from "@/view/components/TextInputInternal";
import PhoneInputInternal from "@/view/components/PhoneInputInternal";
import Comments from "@/view/components/Comments";
import SendMail from "@/view/components/Send-Mail";
import ServiceListing from "@/view/components/ServiceListing";
import AssetListing from "@/view/components/AssetListing";
import AssetStockListing from "@/view/components/AssetStockListing";
import InventoryListing from "@/view/components/InventoryListing";
import ShowValue from "@/view/components/ShowValue";
import Files from "@/view/components/Files";
import { isEmpty } from "lodash";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import ActivateTemplate from "@/view/components/ActivateTemplate";
import DeactivateTemplate from "@/view/components/DeactivateTemplate";
import EventListing from "@/view/components/EventListing";
import SupplierAddressBook from "@/view/components/MemberAddressBook";
import ApiService from "@/core/services/api.service";

export default {
	name: "vendor-detail",
	title: "Detail Customer",
	data() {
		return {
			uuid: null,
			barcode: null,
			activateText: null,
			activateURL: null,
			deactivateText: null,
			deactivateURL: null,
			deleteText: null,
			deleteURL: null,
			formValid: true,
			deleteDialog: false,
			addressDialog: false,
			addressType: null,
			mail_dialog: false,
			vendorAssetCount: "0",
			vendorAssetStockCount: "0",
			vendorinventories: "0",
			vendorServices: "0",
			vemdorcomment: "0",
			vemdorLog: "0",
			vemdorFile: "0",

			addressModel: new Object({
				id: null,
				uuid: null,
				attention: null,
				country: null,
				street_1: null,
				street_2: null,
				city: null,
				state: null,
				postalcode: null,
				phone: null,
				fax: null,
			}),
			personDialog: false,
			personModel: new Object({
				id: null,
				uuid: null,
				salutation: "mr",
				first_name: null,
				last_name: null,
				email: null,
				work_phone: null,
				mobile: null,
			}),
			vendor: {
				id: null,
				uuid: null,
				salutation: "mr",
				first_name: null,
				last_name: null,
				company_name: null,
				display_name: null,
				email: null,
				work_phone: null,
				mobile: null,
				fax: null,
				website: null,
				remark: null,
				billing: {
					id: null,
					uuid: null,
					attention: null,
					country: null,
					street_1: null,
					street_2: null,
					city: null,
					state: null,
					postalcode: null,
					phone: null,
					fax: null,
				},
				shipping: {
					id: null,
					uuid: null,
					attention: null,
					country: null,
					street_1: null,
					street_2: null,
					city: null,
					state: null,
					postalcode: null,
					phone: null,
					fax: null,
				},
			},
			contact_person: [
				{
					id: null,
					uuid: null,
					salutation: "mr",
					first_name: null,
					last_name: null,
					email: null,
					work_phone: null,
					full_name: null,
					default: false,
					mobile: null,
				},
			],
			deactivateDialog: false,
			activateDialog: false,
			contentLoaded: false,
			pageLoading: false,
		};
	},
	methods: {
		doAction(param) {
			switch (param) {
				case "edit":
					this.$router.push({
						name: "vendor-update",
						params: { uuid: this.uuid },
						query: { t: new Date().getTime() },
					});
					break;
				case "clone":
					this.$router.push({
						name: "vendor-create",
						query: { clone: this.uuid, t: new Date().getTime() },
					});
					break;
				case "delete":
					this.deleteDialog = true;
					break;
				case "deactivate":
					this.deactivateDialog = true;
					break;
				case "activate":
					this.activateDialog = true;
					break;
				case "new-address":
					this.addressDialog = true;
					break;
				case "print-label":
					break;
			}
		},
		addressFormat(address) {
			let formattedAddress = [];
			if (address.address_line_1) {
				formattedAddress.push(address.address_line_1);
			}
			if (address.address_line_2) {
				formattedAddress.push(address.address_line_2);
			}
			if (address.address_state) {
				formattedAddress.push(address.address_state);
			}
			if (address.address_postal_code) {
				formattedAddress.push(address.address_postal_code);
			}
			if (address.address_country) {
				formattedAddress.push(address.address_country);
			}
			return formattedAddress.join(", ");
		},
		getVendor() {
			const _this = this;
			GetContact("vendor", _this.uuid)
				.then(({ barcode, contact, contact_person }) => {
					_this.barcode = barcode;
					_this.vendor = contact;
					_this.getVendorAssetCount();
					_this.contact_person = contact_person;
					this.deleteURL = `contact/${contact.uuid}`;
					this.activateText = `${contact.display_name}`;
					this.activateURL = `contact/supplier/${contact.uuid}/activate`;
					this.deactivateText = `${contact.display_name}`;
					this.deactivateURL = `contact/supplier/${contact.uuid}/de-activate`;
				})
				.catch((error) => {
					//this.goBack();
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},

		getVendorAssetCount() {
			ApiService.setHeader();
			ApiService.get(`vendor-asset-count/${this.vendor.id}`)
				.then(({ data }) => {
					this.vendorAssetCount = data.vendorAsset.toString();
					this.vendorAssetStockCount = data.vendorAssetStock.toString();
					this.vendorinventories = data.vendorinventories.toString();
					this.vendorServices = data.vendorServices.toString();
					this.vemdorcomment = data.vemdorcomment.toString();
					this.vemdorLog = data.vemdorLog.toString();
					this.vemdorFile = data.vemdorFile.toString();
				})
				.catch(() => {});
		},

		pageTitle() {
			if (this.uuid) {
				return this.vendor.display_name + " - " + this.vendor.barcode;
			}
			return "Vendor Detail";
		},
		updateAddressDialog(type) {
			this.addressDialog = true;
			this.addressType = type;
			if (type === "billing") {
				this.addressModel = new Object({ ...this.vendor.billing_address });
			} else if (type === "shipping") {
				this.addressModel = new Object({ ...this.vendor.shipping_address });
			}
			console.log(this.vendor.shipping_address);
		},
		addPerson(person) {
			this.personDialog = true;
			this.personModel = new Object();
			if (person && !isEmpty(person)) {
				this.personModel = new Object({
					id: person.id || null,
					uuid: person.uuid || null,
					salutation: person.salutation || null,
					first_name: person.first_name || null,
					last_name: person.last_name || null,
					email: person.email || null,
					mobile: person.phone || null,
					display_name: person.display_name || null,
					designation: person.designation || null,
					primary: person.primary || null,
				});
			}
		},
		async markAsPrimary(uuid) {
			try {
				await MarkAsDefaultContactPerson(uuid);
				this.getVendor();
				this.$store.commit(SET_MESSAGE, [
					{
						model: true,
						message: "Success ! This contact person has been marked as your primary contact person.",
					},
				]);
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			}
		},
		async deletePerson(uuid) {
			try {
				await DeleteContactPerson(uuid);
				this.getVendor();
				this.$store.commit(SET_MESSAGE, [
					{
						model: true,
						message: "Success ! The contact person has been deleted.",
					},
				]);
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			}
		},
	},
	computed: {
		vendorTab: {
			set(val) {
				let query = { ...this.$route.query };
				query.tab = val;
				if (val != this.vendorTab) {
					this.$router.replace({ query });
				}
			},
			get() {
				return this.$route.query.tab || "other";
			},
		},
	},
	components: {
		ShowValue,
		ImageTemplate,
		TextInputInternal,
		PhoneInputInternal,
		CreateOrUpdatePerson,
		Comments,
		ServiceListing,
		Files,
		SupplierAddressBook,
		AssetListing,
		AssetStockListing,
		SendMail,
		InventoryListing,
		DeleteTemplate,
		EventListing,
		ActivateTemplate,
		DeactivateTemplate,
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Contacts", disabled: true },
			{ text: "Supplier", disabled: true },
			{ text: "Detail", disabled: true },
		]);

		const { uuid } = this.$route.params;
		this.uuid = uuid;
		if (!this.uuid) {
			this.goBack();
		}
		this.getVendor();
		/* this.getVendorAssetCount(); */
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
